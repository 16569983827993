export let homeList = [
    {
        id: 'ART001',
        name: 'Aquatic Wonders',
        money: '$517.23',
        number:1,
        type: 'ART',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '8%',
        Creator: 'Valley7797',
        ProductsSold: '0',
        ProductViews: '285',
        Stock: 'In Stock',
        Model: '1000',
        sku: '1000',
        img: '/img/home/product1.jpg'
    },
    {
        id: 'ART002',
        name: 'Aquatic Wonders2',
        money: '$652.28',
        number:1,
        type: 'ART',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '10%',
        Creator: 'Ocean6515',
        ProductsSold: '0',
        ProductViews: '152',
        Stock: 'In Stock',
        Model: '1001',
        sku: '1001',
        img: '/img/home/product2.jpg'
    },
    {
        id: 'ART003',
        name: 'Beyond Earth',
        money: '$765.59',
        number:1,
        type: 'ART',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '8%',
        Creator: 'Echo1448',
        ProductsSold: '0',
        ProductViews: '204',
        Stock: 'In Stock',
        Model: '1002',
        sku: '1002',
        img: '/img/home/product3.jpg'
    },
    {
        id: 'ART004',
        name: 'Beyond Earth2',
        money: '$226.55',
        number:1,
        type: 'ART',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '8%',
        Creator: 'Echo1448',
        ProductsSold: '0',
        ProductViews: '204',
        Stock: 'In Stock',
        Model: '1004',
        sku: '1004',
        img: '/img/home/product4.jpg'
    },
    {
        id: 'ART005',
        name: 'Calm Waters',
        money: '$774.5',
        number:1,
        type: 'ART',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '5%',
        Creator: 'Willow5172',
        ProductsSold: '0',
        ProductViews: '194',
        Stock: 'In Stock',
        Model: '1003',
        sku: '1003',
        img: '/img/home/product5.jpg'
    },
]
export let hotList = [
    {
        id: 'GAME004',
        name: 'Enchanted Night',
        money: '$673.03',
        number:1,
        type: 'GAME',
        Standard: 'ERC-721',
        Chain: 'Ethereum',
        Earnings: '8%',
        Creator: 'Journey7374',
        ProductsSold: '0',
        ProductViews: '87',
        Stock: 'In Stock',
        Model: '1031',
        sku: '1031',
        img: '/img/home/product6.jpg'
    },
    {
        id: 'GAME002',
        name: 'Augmented Existence',
        money: '$185.76',
        number:1,
        type: 'GAME',
        Standard: 'ERC-721',
        Chain: 'Ethereum',
        Earnings: '6%',
        Creator: 'Frost6504',
        ProductsSold: '0',
        ProductViews: '95',
        Stock: 'In Stock',
        sku: '1029',
        Model: '1029',
        img: 'https://ebuynft.com/image/cache/catalog/Game/Augmented%20Existence-550x688h.jpg'
    },
    {
        id: 'GAME003',
        name: 'Echoes of Madness',
        money: '$559.57',
        number:1,
        type: 'GAME',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '7%',
        Creator: 'Wild297',
        ProductsSold: '0',
        ProductViews: '109',
        Stock: 'In Stock',
        sku: '1030',
        Model: '1030',
        img: 'https://ebuynft.com/image/cache/catalog/Game/Echoes%20of%20Madness-550x688h.jpg'
    },
    {
        id: 'PFPS001',
        name: 'Guardian of the Future',
        money: '$113.56',
        number:1,
        type: 'PFPS',
        Standard: 'ERC-1155',
        Chain: 'Ethereum',
        Earnings: '5%',
        Creator: 'Sunset8582',
        ProductsSold: '0',
        ProductViews: '150',
        Stock: 'In Stock',
        sku: '1039',
        Model: '1039',
        img: 'https://ebuynft.com/image/cache/catalog/PFPs/Guardian%20of%20the%20Future-550x688h.jpg',
        imgtitle: 'https://ebuynft.com/image/cache/catalog/PFPs/Guardian%20of%20the%20Future-1130x300w.jpg'
    },
    {
        id: 'GAME005',
        name: 'AEnchanted Night2',
        money: '$429.73',
        number:1,
        type: 'GAME',
        Standard: 'ERC-721',
        Chain: 'Ethereum',
        Earnings: '10%',
        Creator: 'Breeze8496',
        ProductsSold: '0',
        ProductViews: '120',
        Stock: 'In Stock',
        sku: '1032',
        Model: '1032',
        img: 'https://ebuynft.com/image/cache/catalog/Game/Enchanted%20Night2-550x688h.jpg'
    },
]
export let sayList = [
    {
        id: '000011',
        name: '- charlibaltimore',
        text: "Absolutely blown away by the New Drops section! It's incredible to have first dibs on the latest NFT releases. This site has truly elevated my collection to the next level. A must-visit for any serious collector."
    },
    {
        id: '000012',
        name: '- chromebandit',
        text: "The Hot Collections on this site are simply unmatched. I've discovered so many trending NFTs that I wouldn't have found otherwise. It's my go-to resource for staying ahead in the NFT game."
    },
    {
        id: '000013',
        name: '- kellyhorne',
        text: "I'm always excited to check out the Specials section for unique finds. It's like an exclusive club for the most extraordinary NFTs out there. I've managed to snag a couple of limited editions at great prices, thanks to this site."
    },
    {
        id: '000014',
        name: '- TBNWorld',
        text: "The Latest News segment keeps me informed and ahead of the curve in the fast-paced world of NFTs. It's my daily read for industry insights, artist spotlights, and technology updates. Invaluable resource for anyone involved in the NFT space"
    },
    {
        id: '000015',
        name: '- sacredcirclegifts',
        text: "This website has revolutionized my NFT trading experience. From discovering new drops to tracking hot collections and snagging special deals, it's been an incredible journey. The user-friendly interface and up-to-date news section make it a standout platform. Highly recommended!"
    },
]